'use client';

import { Container, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';

// Error components must be Client Components

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
    return (
        <Container>
            <Grid alignContent="center" alignItems="center" textAlign="center" mt="100px">
                <Typography typography="h2" color="white">
                    Something went wrong!
                </Typography>
                <Button
                    color="warning"
                    variant="contained"
                    onClick={
                        // Attempt to recover by trying to re-render the segment
                        () => {
                            reset();
                        }
                    }
                >
                    Try again
                </Button>

                <Typography typography="h4" color="white">
                    {error.name}
                </Typography>
                <Typography typography="" color="white">
                    {error.message}
                </Typography>
            </Grid>
        </Container>
    );
}
